import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
// import ChatComponents from "../components/chat"
import Section01Contacto from "../components/contacto/Section01"

const SecondPage = () => (
  <Layout>
    <Seo title="Sistema de identificación autmatica EVOFINDER " />
    <div className="hidden lg:flex">
      {/* <ChatComponents /> */}
    </div>
    <Section01Contacto />
  </Layout>
)

export default SecondPage
